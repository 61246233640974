<template>
    <div class="ele-body" v-loading="loading">
      <el-card shadow="never">
        <!-- 搜索表单 -->
        <el-form :model="table.where" class="ele-form-search d-flexspabet" @keyup.enter.native="$refs.table.reload()"
          @submit.native.prevent>
          <div style="margin-bottom: 10px;">
            <el-button @click="add()" size="small" v-if="permission.includes('sys:voiceMenu:add')"
              class="ele-btn-icon addbtn ">添加语音菜单
            </el-button>
          </div>
        </el-form>
        <!-- 数据表格 -->
        <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
          highlight-current-row stripe class="my-dialog-name">
          <template slot-scope="{index}">
            <el-table-column type="index" :index="index" label="序号" width="60px" align="center" show-overflow-tooltip />
            <el-table-column prop="menu_id" label="菜单id" show-overflow-tooltip align="center" min-width="80"/>
            <el-table-column prop="om_name" label="一体机名称" show-overflow-tooltip align="center" min-width="130"/>
            <el-table-column prop="audio_name" label="语音名称" show-overflow-tooltip align="center" min-width="200"/>
            <el-table-column prop="lv" label="层级" show-overflow-tooltip align="center" min-width="200"/>
            <el-table-column prop="lv_child" label="子层级" show-overflow-tooltip align="center" min-width="200"/>
            <el-table-column prop="menu_type" label="菜单类型" show-overflow-tooltip align="center" min-width="150">
              <template slot-scope="{row}">{{ row.menu_type == 1 ? '座机欢迎菜单' : (row.menu_type == 2 ? '手机欢迎菜单' : (row.menu_type == 3 ? '一级菜单' : '') )}}</template>
            </el-table-column>
            <el-table-column prop="mac" label="一体机mac" show-overflow-tooltip align="center" min-width="150"/>
            <el-table-column label="操作" align="center" min-width="200" :resizable="false">
              <template slot-scope="{row}">
                <el-link @click="view(row)" icon="el-icon-view" type="primary" :underline="false">查看</el-link>
                <el-link @click="remove(row)" icon="el-icon-delete" type="danger" :underline="false"
                  v-if="permission.includes('sys:omapi:delete')">删除
                </el-link>
              </template>
            </el-table-column>
          </template>
        </ele-data-table>
      </el-card>
      <!-- 添加弹窗 -->
      <el-dialog v-dialogDrag :title="editForm.id?'编辑语音菜单':'添加语音菜单'" :visible.sync="showEdit" @closed="editForm={}"
        :destroy-on-close="true" :lock-scroll="false">
        <el-card shadow="never">
          <el-form :model="editForm" ref="editForm" :rules="editRules" label-width="150px">
            <el-form-item label="一体机菜单id：" prop="omid">
                <el-select v-model="editForm.om_name"  @change="handleAio(editForm.om_name)" placeholder="请选择一体机菜单id"
                clearable>
                <el-option v-for="option in AIOList" :label="option.om_id" :value="option.id"
                    :key="option.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="菜单id：" prop="menuId">
                <el-select v-model="editForm.menu_id" @change="handleMenu(editForm.menu_id)" placeholder="请选择菜单id"
                clearable>
                <el-option v-for="option in menuList" :label="option.menuId" :value="option.menuId"
                    :key="option.menuId"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="语音名称：" prop="audioName">
                <el-select v-model="editForm.audio_name" @change="handleVoice(editForm.audio_name)" placeholder="请选择语音名称"
                clearable>
                <el-option v-for="option in voiceList" :label="option.label" :value="option.value"
                    :key="option.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="按键个数：" prop="audioName">
                <el-select v-model="editForm.info_length" @change="handleKey(editForm.info_length)" placeholder="请选择语音名称"
                clearable>
                <el-option v-for="option in keyList" :label="option.info_length" :value="option.info_length"
                    :key="option.info_length"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="播放次数：" prop="audioName">
                <el-select v-model="editForm.playCount" @change="handlePlay(editForm.playCount)" placeholder="请选择语音名称"
                clearable>
                <el-option v-for="option in playList" :label="option.playCount" :value="option.playCount"
                    :key="option.playCount"></el-option>
                </el-select>
            </el-form-item>
            
            <el-form-item label="层级：" prop="lv">
                <el-select v-model="editForm.lv" @change="handleLv(editForm.lv)" placeholder="请选择语音名称"
                clearable>
                <el-option v-for="option in lvList" :label="option.lv" :value="option.lv"
                    :key="option.lv"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="子层级：" prop="lv_child">
                <el-select v-model="editForm.lv_child" @change="handleLvChild(editForm.lv_child)" placeholder="请选择语音名称"
                clearable>
                <el-option v-for="option in lvChildList" :label="option.lv_child" :value="option.lv_child"
                    :key="option.lv_child"></el-option>
                </el-select>
            </el-form-item>
            
            
            
            
            <el-form-item label="菜单类型：" prop="menu_type">
              <el-radio-group v-model="editForm.menu_type">
                <el-radio :label="1">座机欢迎菜单</el-radio>
                <el-radio :label="2">手机欢迎菜单</el-radio>
                <el-radio :label="3">一级菜单</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </el-card>
        <div slot="footer">
          <el-button @click="showEdit=false">取消</el-button>
          <el-button type="primary" @click="save">提交</el-button>
        </div>
        <audio src=""></audio>
      </el-dialog>
      <el-dialog v-dialogDrag title="查看用户详情" :visible.sync="dialogViewShow" center width="780px" 
        :destroy-on-close="true" :lock-scroll="false">
        <el-card shadow="never">
          <el-form :model="checkForm" label-width="150px">
            <el-form-item label="菜单id：" >
              {{checkForm['@id']}}
            </el-form-item>
            <el-form-item label="结束按键：" >
              {{checkForm.exit}}
            </el-form-item>
            <el-form-item label="语音名称：" >
              {{checkForm.voicefile}}
            </el-form-item>
            <el-form-item label="按键长度：" >
              {{checkForm.infolength}}
            </el-form-item>
            <el-form-item label="播放次数：" >
              {{checkForm.repeat}}
            </el-form-item>
          </el-form>
        </el-card>
      </el-dialog>
    </div>
  </template>
  
  <script>
    import {
      mapGetters
    } from 'vuex'
  
    export default {
      name: 'voiceMenu',
      components: {},
      provide() { // 父组件中返回要传给下级的数据
        return {
          reload: this.reload
        }
      },
      data() {
        return {
          timer: 0,
          isRouterAlive: true,
          table: {
            url: '/omapi/getMenuList',
            where: {}
          }, // 表格配置
          provArr: [],
          cityArr: [],
          districtArr: [],
          choose: [], // 表格选中数据
          showEdit: false, // 是否显示表单弹窗
          editForm: {
            om_name: '',
            menu_id: '',
            audio_name: '',
            menu_type: '',
            info_length: '',
            playCount: '',
            lv:'',
            lv_child:''
            
          }, // 表单数据
          checkForm: {},
          userInfo: {},
          editRules: { // 表单验证规则
            // omid: [{
            //   required: true,
            //   message: '请选择一体机菜单id',
            //   trigger: 'change'
            // }],
            // menuId: [{
            //   required: true,
            //   message: '请选择菜单id',
            //   trigger: 'change'
            // }],
            // audioName: [{
            //   required: true,
            //   message: '请选择语音名称',
            //   trigger: 'change'
            // }]
          },
          websock: {},
          player: '',
          wsuri: '',
          loading: false,
          AIOList: [],
          menuList: [],
          omid: '',
          menuId: '',
          voiceList: [],
          keyList: [],
          playList: [],
          lvChildList:[],
          lvList:[],
          audioName: '',
          menu_type: '',
          dialogViewShow: false,
        }
      },
      created() {
        
      },
      beforeRouteLeave(to, form, next) {
        next()
        //console.log('离开了页面')
        this.player = ''
        this.websock.close();
      },
      computed: {
        ...mapGetters(['permission']),
      },
      mounted() {
        
      },
      methods: {
        reload() {
          
        },
        //选择一体机
        handleAio(omid){
          console.log('一体机omid',omid);
          this.omid = omid;
          this.voiceList = [];
          this.audioName = '';
          //语音名称
          this.$http.post('/Omapi/getAudioList', {
            id: this.omid,
          }).then(res => {
            // console.log('语音名称的res=>>>',res);
            if(res.data.code == 0){
              res.data.data.forEach(item => {
                let obj = {};
                obj.value = item;
                obj.label = item;
                this.voiceList.push(obj);
              })
              // console.log('this.voiceList',this.voiceList);
            } else {
              this.$message({
                type: 'error',
                message: res.data.msg
              })
            }
          }).catch(e => {
            this.$message.error(e.message)
          })
        },
        //菜单id
        handleMenu(menuId){
          console.log('菜单menuId',menuId);
          this.menuId = menuId;
        },
        //语音名称
        handleVoice(audioName){
          this.audioName = audioName
        },
        //按键个数
        handleKey(info_length){
          this.info_length = info_length
        },
        handleLvChild(lv_child){
          this.lv_child=lv_child
        },
        handleLv(lv){
           this.lv = lv
        },
        //播放次数
        handlePlay(playCount){
          this.playCount = playCount
        },
        add() {
          this.showEdit = true;
          this.menuList = [];
          this.voiceList = [];
          this.omid = '';
          this.menuId = '';
          this.audioName = '';
          this.menu_type = '';
          this.getAIOList()
        },
        //获取一体机列表
        getAIOList(){
          for (let index = 1; index < 51; index++) {
            this.menuList.push({menuId:index})
          }
          for (let index = 1; index < 11; index++) {
            this.keyList.push({info_length:index})
          }
          
          for (let index = 1; index < 5; index++) {
            this.lvList.push({lv:index})
          }
          
          for (let index = 1; index < 5; index++) {
            this.lvChildList.push({lv_child:index})
          }
          
          
          for (let index = 1; index < 6; index++) {
            this.playList.push({playCount:index})
          }
          // console.log('this.menuList',this.menuList);
          //一体机列表
          this.$http.post('/omapi/omapiList', {}).then(res => {
            // console.log('一体机列表的res=>>>',res);
            if(res.data.code == 0){
              this.AIOList = res.data.data;
            } else {
              this.$message({
                type: 'error',
                message: res.data.msg
              })
            }
          }).catch(e => {
            this.$message.error(e.message)
          })
        },
        //查看
        view(row){
          console.log('row',row);
          this.dialogViewShow = true;
          this.$http.post('/Omapi/menuInfo', {
            omid: row.om_id,
            menuId: row.menu_id
          }).then(res => {
            console.log('详情的res=>>>',res);
            console.log('详情的res=>>>',res.data.data.Status.menu['@id']);
            this.checkForm = res.data.data.Status.menu;
            if(res.data.code == 0){
              
            } else {
              this.$message({
                type: 'error',
                message: res.data.msg
              })
            }
          }).catch(e => {
            this.$message.error(e.message)
          })
        },
        // 删除
        remove(row) {
          console.log('row',row);
          this.$http.post('/Omapi/deleteMenu', {
            omid: row.om_id,
            menuId: row.menu_id,
          }).then(res => {
            console.log('删除的res',res);
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: '删除成功'
              })
              this.$refs.table.reload()
            } else {
              this.$refs.table.reload()
              this.$message({
                type: 'error',
                message: res.data.msg
              })
            }
          }).catch(e => {
            loading.close()
            this.$message.error(e.message)
          })
        },
        /**
         * 保存编辑
         */
        save() {
          console.log('this.editForm.om_name',this.editForm.om_name,this.editForm.menu_id,this.editForm.audio_name,this.editForm.menu_type,this.editForm.info_length);
          if(this.editForm.om_name && this.editForm.menu_id && this.editForm.audio_name && this.editForm.info_length && this.editForm.playCount){
            this.$http.post('/Omapi/addMenu', {
              omid: this.editForm.om_name,
              menuId: this.editForm.menu_id,
              audioName: this.editForm.audio_name,
              infolength:  this.editForm.info_length,
              playCount: this.editForm.playCount,
              menu_type: this.editForm.menu_type,
              lv:this.editForm.lv,
              lv_child:this.editForm.lv_child
            }).then(res => {
              console.log('保存的res',res);
              if(res.data.code == 0){
                this.showEdit = false
                this.$refs.table.reload()
                this.$message({
                  type: 'success',
                  message: '添加成功'
                })
              } else {
                this.$refs.table.reload()
                this.showEdit = false
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            }).catch(e => {
              this.$message.error(e.message)
            })
          } else {
            this.$message({
              type: 'warning',
              message: '请完善信息'
            })
          }
        },
      }
    }
  </script>
  
  <style scoped>
    .ele-block>>>.el-upload,
    .ele-block>>>.el-upload-dragger {
      width: 100%;
    }
  
    /deep/ .el-dialog {
      margin-top: 30px !important;
    }
  </style>
  